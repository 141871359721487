export { configClass };

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import { encryptDeviceInfo } from "@/utilities.js";
import { default as providerConfig, _firebase } from "../provider-config.js";

import { setNotificationsToLocalStorage, getDeepLink } from "./../utilities.js";

let counterFlag = 1;
class configClass {
  constructor() {
    this.availabilityList = [];
    this.page = 1;
    this.pageSize = 15;
    this.ISTTLACTIVE = false;

    const expiry = localStorage.getItem('config-expire');
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (!expiry || now.getTime() > expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      this.ISTTLACTIVE = false;
      
    }
  }



  loadConfig() {

   if (localStorage.getItem('appConfig') && this.ISTTLACTIVE) {
    this.startUpActions(JSON.parse(localStorage.getItem('appConfig')));
    return;
   }
    store
      .dispatch("getConfig")
      .then((response) => {
        const ttl =  new Date().getTime() + ((1 * 60) * 1000);
        localStorage.setItem("config-expire", ttl);
        localStorage.setItem("appConfig", JSON.stringify(response));

        // Call the func
        this.startUpActions(response);
      })
      .catch((error) => {
        let localAppConfig = localStorage.getItem("appConfig");

        if (localAppConfig) {
          localAppConfig = JSON.parse(localAppConfig);

          if (Object.keys(localAppConfig).length > 0) {
            this.startUpActions(localAppConfig);
          }
        }

        console.error(error);
      });
  }


  loadDeckingConfig() {
    return new Promise ((resolve, reject) => {
      store.dispatch("actDeckingCallService").then((res) => {
        if (!res.data.success && counterFlag <= 2) {
          localStorage.removeItem("deviceToken");
          this.loadConfig()
          counterFlag++;
          return;
        }
        store
        .dispatch("getDeckingConfig", res.data.success)
        .then((response) => {
          localStorage.setItem("appDeckingConfig", JSON.stringify(response));
          resolve(true)
        })
        .catch((error) => {
         console.error("error", error);
         reject(false)
        });
      })



    })
  
  }
  initFirebaseConfig() {
    // if (firebase.apps.length) return;

    let firebaseConfig = {
      apiKey: "AIzaSyA_N0Eq94sJVGfaEDLBH2vlCxaQ7kpD_gk",
      authDomain: "saina-play-9c2ac.firebaseapp.com",
      databaseURL: "https://saina-play-9c2ac-default-rtdb.firebaseio.com",
      projectId: "saina-play-9c2ac",
      storageBucket: "saina-play-9c2ac.appspot.com",
      messagingSenderId: "63008530419",
      appId: "1:63008530419:web:a6f244071b810597c97c86",
      measurementId: "G-DHGSSK592N"
    };


    // If firebase config is - Prepord
    // if (_firebase === "preprod") {
      firebase.initializeApp(firebaseConfig);
    // }
    // // If firebase config is - PrepordPOC
    // else if (_firebase === "preprodPOC") {
    //   firebase.initializeApp(fireBasePOC);
    // }
    // // If firebase config is - Net5 Prod
    // else if (_firebase === "net5") {
    //   firebase.initializeApp(firebaseConfig);
    // }
    // // If firebase config is - NammaFlix Prod
    // else if (_firebase === "nammaflix") {
    //   console.log("nammaflix");
    //   firebase.initializeApp(nammaflixfirebaseConfig);
    // }
    // // If firebase config is - Noorplay prod
    // else if (_firebase === "noorplay") {
    //   firebase.initializeApp(noorplayfirebaseConfig);
    // }

    // Init firebase messaging and subscribe to basic topics
    this.initFirebaseMessaging();
  }

  startUpActions(response) {
    store.commit("commitappconfig");
    store.commit("commitbaseurls", response.providerDetails.baseURLs);
    store.commit("commitsubscriberdetails");

    if (!localStorage.getItem("setDisplayLanguageCode")) {
      localStorage.setItem("setDisplayLanguageCode", 'eng');
    }

    providerConfig(response.providerDetails.providerId);
		// Fetch i18n Language From API
    const deviceToken = localStorage.getItem("deviceToken");

    const appconfigLocal = localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : ''
		if(!deviceToken) {
   	 encryptDeviceInfo().then((response) => {
      store
        .dispatch("deviceRegister", response)
        .then((response) => {
          // Load the Firebase Config
          this.initFirebaseConfig();

          if (response.success) {
            localStorage.setItem("deviceToken", response.success);

          
            // Fetch i18n Language From API
            const localLanguage = localStorage.getItem("setDisplayLanguageCode");

            if(!localLanguage) {
              this.fetchDisplayLanguage().then(data => {
                
                  if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                    this.loadDeckingConfig().then(() => {
                      eventBus.$emit("configLoaded");

                    });
                  } else {
                    eventBus.$emit("configLoaded");
                  }
                
              }).catch(err => {
                if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                  this.loadDeckingConfig().then(() => {
                    eventBus.$emit("configLoaded");

                  });
                } else {
                  eventBus.$emit("configLoaded");
                }
              });
            } else {
              if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                this.loadDeckingConfig().then(() => {
                  eventBus.$emit("configLoaded");

                });
              } else {
                eventBus.$emit("configLoaded");
              }
            }

            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }
           

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();
          } else {
            eventBus.$emit("deviceRegister_error", response);

            eventBus.$emit("configLoaded");

            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();
          }
        })
        .catch((error) => {
          console.error("register error", error);
          eventBus.$emit("deviceRegister_error", error);
          eventBus.$emit("configLoaded");
        });
    });
		} else {
      if (localStorage.getItem("appDeckingConfig") && this.ISTTLACTIVE ) {

        eventBus.$emit("configLoaded");
      } else {
         if (appconfigLocal.featureEnabled.isDeckingEnabled) {
          this.loadDeckingConfig().then(() => {
            eventBus.$emit("configLoaded");

          });
        } else {
          eventBus.$emit("configLoaded");
        }
      }
				this.initFirebaseConfig();
			 
    
            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();

				}

    import(/* webpackChunkName: "userClass" */ /* webpackMode: "lazy" */ "./userClass").then((user) => {
      this.user = new user.userClass();
    });
  }

  setupServiceWorkers() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((reg) => {
          navigator.serviceWorker.addEventListener("message", (message) => {
            navigator.serviceWorker.ready.then(function(registration) {
              // const deepLinkParamter = message.data["firebase-messaging-msg-data"].data.data;
              // const deepLinkParamter = message.data.data.data;

              // const storageParameter = message.data["firebase-messaging-msg-data"].data;
              const storageParameter = message.data.data;

              // let data = message.data["firebase-messaging-msg-data"].data;
              let data = message.data.data;

              let notificationTitle = "";
              let notificationOptions = {};

              // let link = getDeepLink(deepLinkParamter);

              if (data) {
                notificationTitle = data.title;
                notificationOptions = {
                  body: data.message || data.body,
                  // icon: data.icon,
                  // click_action: link,
                  image: data.picture,
                  // data: {
                  //   action: link,
                  // },
                };
              }
              registration.showNotification(notificationTitle, notificationOptions);

              // setNotificationsToLocalStorage(storageParameter);

              // eventBus.$emit("getNotificationsHeaderCount");
            });
          });
        })
        .catch((err) => console.error(`sw error:${err}`));
    }
  }

  actNewDeviceRegister() {
    return new Promise((resolve, reject) => {
      // localStorage.removeItem("random");
      encryptDeviceInfo()
        .then((response) => {
          store.dispatch("deviceRegister", response).then((response) => {
            store.commit("setToken", response.success);
            localStorage.setItem("subscriberToken", response.success);
            resolve(true);
          });
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  fetchAvailability() {
    let payload = {
      page: this.page,
      pagesize: this.pageSize,
    };
    store
      .dispatch("availabilityList", payload)
      .then((response) => {
        //storing total count in local variable.
        let totalCount = response.data.totalcount;
        //appeding availability response for the exising array.
        this.availabilityList = [...this.availabilityList, ...response.data.data];
        
        //commit the updated availability list.
        localStorage.setItem("availabilityList", JSON.stringify(this.availabilityList));

        store.commit("setAvailabilityList", this.availabilityList);
        //checking if the lenght of availabilties less than total count. If true call availability list agian.
        if (this.availabilityList.length < totalCount) {
          this.page++;
          this.fetchAvailability();
        }
      })
      .catch((error) => console.error(error));
  }

  fetchSubscriptionList() {
    let payload = {
      subscriptionstatus: "ALL",
    };

    store
      .dispatch("listSubscription", payload)
      .then((response) => {
        if (!response.data.reason) {
          store.commit("setSubscriptionList", response.data.data);
        } else {
          store.commit("setSubscriptionList", []);
        }
      })
      .catch((error) => {
        store.commit("setSubscriptionList", [{}]);
      });
  }

  fetchPurchasedList() {
    store
      .dispatch("listPurchases")
      .then((response) => {
        if (!response.data.reason) {
          store.commit("setPurchasesList", response.data.data);
        }
      })
      .catch((error) => console.error(error));
  }

  fetchDisplayLanguage() {
    return new Promise((resolve, reject) => {
      store
        .dispatch("getDisplayLanguageAPI")
        .then((response) => {

          if(response.reason) {
            reject(false);
          } else if (!response.reason) {
            let languages = response.data;
            store.commit("setDisplayLanguage", languages);
  
            if (Array.isArray(languages) && languages.length) {
              let index = languages.findIndex((element) => {
                return element.isdefault === "YES";
              });
  
              if (index > -1) {
                let code = languages[index].language;             
                localStorage.setItem("setDisplayLanguageCode", code);
                resolve(true);
              }
            }
          }
        })
        .catch((error) => {
          reject(false);
          console.error(error)
        });
    })
  }

  fetchCurrentCountry() {
    fetch(`${store.getters.vSmsBaseUrl}subscriberv2/v1/getcountry`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        if(!response.reason) {
          localStorage.setItem("currentUserCountry", JSON.stringify(response));
          store.commit("setCountry", response);
        } else {
          store.commit("setCountry", null);
        }
      })
      .catch((error) => console.error("Country Code", error));
  }

  checkCurrentLanguage() {
    const localLanguage = localStorage.getItem("setDisplayLanguageCode");

    if(!localLanguage) {
      this.fetchDisplayLanguage();
    }
  }

  fetchAllLanguagesFullForm() {
    store
      .dispatch("getLanguagesByCodes")
      .then((response) => {
        store.commit("setAllLanguageFullForm", response);
      })
      .catch((error) => {
        console.error("Langauges error -- ", error);
      });
  }

  checkForMovieResponse() {
    let appconfig = JSON.parse(localStorage.getItem("appConfig"));
    let moviesSection = appconfig.screens.filter((el) => {
      return el.id === "MOVIES";
    });
    let screen = moviesSection[0].sections[0];

    let payload = {
      endpoint: screen.endpoint,
      params: screen.parameters,
    };

    store
      .dispatch("contentList", payload)
      .then((response) => {
        if (response.data.errorcode === 7109) {
          store.commit("setIsMovieEnabled", "No");
        } else {
          store.commit("setIsMovieEnabled", "Yes");
        }
      })
      .catch((error) => console.error(error));
  }

  initFirebaseMessaging() {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .requestPermission()
        .then(function() {
          return firebase.messaging().getToken();
        })
        .then(function(token) {
          localStorage.setItem("fcmToken", token);

          // Subscribe to the topic ("Broadcast")
          store
            .dispatch("fcmMessagingSubscribeToTopic", "BROADCAST")
            .then((data) => {
            })
            .catch((err) => {
            });

          // Subscribe to the topic ("Device type")
          store
            .dispatch("fcmMessagingSubscribeToTopic", "PC")
            .then((data) => {
            })
            .catch((err) => {
            });
        })
        .catch(function(error) {
        });
    }
  }
}
